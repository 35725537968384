import { createSelector } from 'reselect'
// import { getAreaRange, getPriceRange } from './housesStore'
import { getCurrentPromotion } from './promotionStore'
import { findById, selectItem } from '../utils/utils'

const resetState = {
  floors: [],
  typologies: [],
  orientations: [],
  roomsOptions: [],
  houseStates: [],
  priceRange: [0, 0], //[min,max]
  areaRange: [0, Number.MAX_VALUE] //[min,max]
}

export const filterStore = (set, get) => ({
  filter: {
    ...resetState,
    reset: () => {
      const promotion = getCurrentPromotion(get())
      set((prev) => ({
        filter: {
          ...prev.filter,
          ...resetState,
          priceRange: promotion?.data.priceRange || [0, 0],
          floors: promotion?.floors?.length > 0 ? [promotion.floors[0].id] : []
        }
      }))
    },
    setFloor: (id) => {
      set((prev) => ({ filter: { ...prev.filter, floors: [id] } }))
    },
    setFloors: (ids) => {
      set((prev) => ({ filter: { ...prev.filter, floors: ids } }))
    },
    setTypology: (id) => {
      set((prev) => ({ filter: { ...prev.filter, typologies: [id] } }))
    },
    setTypologies: (ids) => {
      set((prev) => ({ filter: { ...prev.filter, typologies: ids } }))
    },
    setHouseState: (id) => {
      set((prev) => ({ filter: { ...prev.filter, houseStates: [id] } }))
    },
    setHousesStates: (ids) => {
      set((prev) => ({ filter: { ...prev.filter, houseStates: ids } }))
    },
    setOrientation: (id) => {
      set((prev) => ({ filter: { ...prev.filter, orientations: [id] } }))
    },
    setOrientations: (ids) => {
      set((prev) => ({ filter: { ...prev.filter, orientations: ids } }))
    },
    setRoomOption: (id) => {
      set((prev) => ({ filter: { ...prev.filter, roomsOptions: [id] } }))
    },
    setRoomOptions: (ids) => {
      set((prev) => ({ filter: { ...prev.filter, roomsOptions: ids } }))
    },
    toggleRoomOption: (id) => {
      const options = filterRoomOptionsSelector(get())
      setRoomsOptionAction(get())(
        selectItem(options, id, !options.includes(id))
      )
    },
    setAreaRange: (min, max) => {
      set((prev) => ({ filter: { ...prev.filter, areaRange: [min, max] } }))
    },
    setPriceRange: (min, max) => {
      set((prev) => ({ filter: { ...prev.filter, priceRange: [min, max] } }))
    }
  }
})

export const setPriceRangeAction = (state) => state.filter.setPriceRange
export const setAreaRangeAction = (state) => state.filter.setAreaRange
export const setRoomOptionAction = (state) => state.filter.setRoomOption
export const setRoomsOptionAction = (state) => state.filter.setRoomOptions
export const toggleRoomOptionAction = (state) => state.filter.toggleRoomOption
export const setOrientationsAction = (state) => state.filter.setOrientations
export const setOrientationAction = (state) => state.filter.setOrientation
export const setHousesStatesAction = (state) => state.filter.setHousesStates
export const setHouseStateAction = (state) => state.filter.setHouseState
export const setTypologiesAction = (state) => state.filter.setTypologies
export const setTypologyAction = (state) => state.filter.setTypology
export const setFloorsAction = (state) => state.filter.setFloors
export const setFloorAction = (state) => state.filter.setFloor
export const resetFilterAction = (state) => state.filter.reset

export const filterTypologiesSelector = (state) => state.filter.typologies
export const filterOrientationsSelector = (state) => state.filter.orientations
export const filterPriceRangeSelector = (state) => state.filter.priceRange
export const filterAreaRangeSelector = (state) => state.filter.areaRange
export const filterRoomOptionsSelector = (state) => state.filter.roomsOptions
export const filterFloorsSelector = (state) => state.filter.floors
export const filterHouseStatesSelector = (state) => state.filter.houseStates

const roomFilter = (nRooms, roomsOptions, promotionRoomOptions) => {
  if (!roomsOptions || !promotionRoomOptions) {
    return false
  }
  if (roomsOptions.length === 0) {
    return true
  }
  for (let roId of roomsOptions) {
    const ro = findById(promotionRoomOptions, roId)
    if (ro) {
      if (nRooms === ro.nRooms) {
        return true
      }
    }
  }
  return false
}

export const getFilterHouses = createSelector(
  [
    getCurrentPromotion,
    filterFloorsSelector,
    filterTypologiesSelector,
    filterPriceRangeSelector,
    filterAreaRangeSelector,
    filterOrientationsSelector,
    filterRoomOptionsSelector
  ],
  (
    promotion,
    floors,
    typologies,
    priceRange,
    areaRange,
    orientations,
    roomOptions
  ) => {
    return promotion
      ? promotion.data.houses.filter((house) => {
          if (promotion.withPrinex) {
            return (
              house.rentState === '5' &&
              (floors.length === 0 || floors.includes(house.floor)) &&
              (typologies.length === 0 ||
                typologies.includes(house.typology)) &&
              (orientations.length === 0 ||
                orientations.includes(house.orientation)) &&
              house.price >= priceRange[0] &&
              house.price <= priceRange[1] &&
              house.area >= areaRange[0] &&
              house.area <= areaRange[1] &&
              roomFilter(house.rooms, roomOptions, promotion.data.roomOptions)
            )
          } else {
            return true
          }
        })
      : []
  }
)
