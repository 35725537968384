import { styled } from '@mui/material/styles'
import { isMobile } from '../../modules/mainStore'
import { getCurrentPromotion } from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'

const Root = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: 12,
  width: '100%',
  height: theme.topBar.height,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
}))
const Logo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  width: 'auto',
  maxWidth: '40%',
  height: '100%',
  objectFit: 'contain',
  paddingRight: mobile ? 12 : 64
}))
const Title = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  textTransform: 'uppercase',
  fontSize: mobile ? 14 : 24,
  display: 'flex',
  flexFlow: mobile ? 'column' : 'row',
  justifyContent: 'center',
  alignItems: 'center',
  userSelect: 'none'
}))

const Promotion = styled('div')(({ theme }) => ({
  fontFamily: `NeinorFina, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  fontWeight: 200,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 2
}))
const Separator = styled('div')(({ theme }) => ({
  height: 6,
  width: 6,
  borderRadius: '50%',
  backgroundColor: theme.palette.secondary.main,
  margin: '0 8px',
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}))
const City = styled('div')(({ theme }) => ({
  fontFamily: `ITCAvantGardeStd-Md, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  fontWeight: 400,
  // fontWeight: 1000,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center'
  // fontSize: '110%'
}))

export const TopBar = () => {
  const mobile = useH3DStore(isMobile)
  const name = useH3DStore((st) => getCurrentPromotion(st)?.name)
  return (
    <Root>
      <Logo src={'images/toolbar_logo.png'} mobile={mobile} />
      <Title mobile={mobile}>
        <Promotion>{name} Rental Homes</Promotion>
        {/* <Promotion>Hacienda Rental Homes</Promotion>
        {!mobile && <Separator />}
        <City>{name}</City> */}
      </Title>
    </Root>
  )
}
export default TopBar
