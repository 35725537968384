import { createSelector } from 'reselect'
import { MAIN_MODE, setMainModeAction } from './mainStore'
import { findById } from '../utils/utils'

export const modelStore = (set, get) => ({
  model: {
    models: [],
    currentModel: '',
    setModels: (models) => {
      set((prev) => ({
        model: {
          ...prev.model,
          models: models.map((m) => ({ ...m, loaded: false, loading: false })),
          currentModel: ''
        }
      }))
    },

    setCurrentModel: (id) => {
      if (id !== currentModelSelector(get())) {
        set((prev) => ({
          model: {
            ...prev.model,
            currentModel: id,
            models: modelsSelector(get()).map((m) => {
              if (m.id === id) {
                return { ...m, loaded: false, loading: true }
              }
              return m
            })
          }
        }))
      }
    },
    mainModelLoaded: () => {
      setMainModeAction(get())(MAIN_MODE.LOADING_MODEL_2)
    },
    currentModelLoaded: () => {
      const id = currentModelSelector(get())
      set((prev) => ({
        model: {
          ...prev.model,
          models: modelsSelector(get()).map((m) => {
            if (m.id === id) {
              return { ...m, loaded: true, loading: false }
            }
            return m
          })
        }
      }))
      setMainModeAction(get())(MAIN_MODE.VIEW_3D_LOADING_DATA)
    }
  }
})
export default modelStore

export const setModelsAction = (st) => st.model.setModels
export const setCurrentModelAction = (st) => st.model.setCurrentModel
export const mainModelLoadedAction = (st) => st.model.mainModelLoaded
export const currentModelLoadedAction = (st) => st.model.currentModelLoaded

export const modelsSelector = (st) => st.model.models
export const currentModelSelector = (st) => st.model.currentModel

export const getCurrentModel = createSelector(
  [currentModelSelector, modelsSelector],
  (currentModel, models) => {
    const model = findById(models, currentModel)
    if (!model) {
      return null
    }
    const base = `models/${currentModel}`
    return {
      ...model,
      src: `${base}/model/model.${model.type}`,
      backgroundSrcs: [
        `${base}/background/px.jpg`,
        `${base}/background/nx.jpg`,
        `${base}/background/py.jpg`,
        `${base}/background/ny.jpg`,
        `${base}/background/pz.jpg`,
        `${base}/background/nz.jpg`
      ]
    }
  }
)

export const getCurrentModelNodesById = createSelector(
  [getCurrentModel],
  (currentModel) => {
    return currentModel?.nodes.length > 0
      ? currentModel.nodes.reduce((acc, node) => {
          acc[node.id] = {
            ...node,
            material: {
              ...node.material,
              fullMap: node.material.map
                ? `models/${currentModel.id}/model/${node.material.map}`
                : ''
            }
          }
          return acc
        }, {})
      : null
  }
)

/*
export const getMaterials = createSelector(
  [materialsSelector, currentModelSelector],
  (materials, modelId) => {
    return materials
      ? materials.map((m) => {
          return {
            ...m,
            values: {
              ...m.values,
              fullMap: `models/${modelId}/model/${m.values.map}`
            }
          }
        })
      : []
  }
)

export const getMaterialSimple = (st) => (id) =>
  findById(materialsSelector, id)

export const getMaterialMemo = () =>
  createSelector([getMaterials, (_, matId) => matId], (materials, matId) => {
    return findById(materials, matId)
  })
  */
