import { styled, alpha, darken } from '@mui/material/styles'
import { useCallback } from 'react'
import { isMobile } from '../../modules/mainStore'
import { getCurrentHouse } from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile' && prop !== 'selected'
})(({ theme, selected, mobile }) => ({
  position: 'absolute',
  top: 28,
  right: 28,
  zIndex: theme.zIndex.overlay,
  width: mobile ? 64 : 100,
  height: mobile ? 64 : 140,
  padding: 0,
  boxSizing: 'border-box',
  // margin: mobile ? 8 : 12,
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: selected
    ? theme.palette.secondary.main
    : theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: alpha(darken(theme.palette.primary.main, 0.2), 0.6)
    }
  }
}))

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto'
}))
const Title = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  fontSize: mobile ? 8 : 14,
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: `ITCAvantGardeStd-Md, Neinor ,Roboto, Helvetica, Arial, sans-serif`,
  fontWeight: 400
}))

export const BookButton = ({ src, title, selected }) => {
  const mobile = useH3DStore(isMobile)
  const house = useH3DStore(getCurrentHouse)

  const handleClick = useCallback(() => {
    // console.log(
    //   `https://apps.prinex.com/comercializacion_digital/public/incrustado/condicionesAlquiler?jsondata={"parametros":{"idubapi":"3519300104","cod_subdominio_agrupacion":"neinor","codigoPromocion":"${house.codPromocion}","codigoActivo":"${house.cod}","mostrarBoton":true}}`
    // )
    window.open(
      `https://apps.prinex.com/comercializacion_digital/public/incrustado/condicionesAlquiler?jsondata={"parametros":{"idubapi":"3519300104","cod_subdominio_agrupacion":"neinor","codigoPromocion":"${house.codPromocion}","codigoActivo":"${house.cod}","mostrarBoton":true}}`,
      '_blank',
      'noopener,noreferrer'
    )
  }, [house])

  return (
    <Root selected={selected} mobile={mobile} onClick={handleClick}>
      <Image src={src} />
      <Title mobile={mobile} selected={selected}>
        {title}
      </Title>
    </Root>
  )
}
export default BookButton
