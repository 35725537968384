import { useCallback, useEffect, useRef, useState } from 'react'

import useH3DStore from '../../../../modules/useH3DStore'
import {
  currentModelLoadedAction,
  getCurrentModelNodesById,
  mainModelLoadedAction
} from '../../../../modules/modelStore'
import {
  backgroundActiveSelector,
  setProgressAction
} from '../../../../modules/mainStore'

import { EntornoGoogle } from './EntornoGoogle'
import { Towers } from './Towers'
import { EntornoObjects } from './EntornoObjects'
import { MainTower } from './MainTower'
import { TowersBase } from './TowersBase'
import { EntornoArbolesAcera } from './EntornoArbolesAcera'
// import { EntornoMobiliarioUrbano } from './EntornoMobiliarioUrbano'
// import { EntornoMobiliarioTerraza } from './EntornoMobiliarioTerraza'
import { Sky } from '../Sky'
import { Torre4 } from './Torre4'
import Houses from '../Houses'
import { getCurrentPromotion } from '../../../../modules/promotionStore'

export const NeinorSky = ({ envMap, ...props }) => {
  const [l1, setL1] = useState(false)
  const [l2, setL2] = useState(false)
  const [l3, setL3] = useState(false)
  const [l4, setL4] = useState(false)
  const [l5, setL5] = useState(false)
  const [l6, setL6] = useState(false)
  const [l7, setL7] = useState(false)
  const [l8, setL8] = useState(false)
  const [l9, setL9] = useState(false)
  const promotion = useH3DStore(getCurrentPromotion)
  const nodes = useH3DStore(getCurrentModelNodesById)
  const backgroundHidden = !useH3DStore(backgroundActiveSelector)
  const setProgress = useH3DStore(setProgressAction)
  const mainModelLoaded = useH3DStore(mainModelLoadedAction)
  const currentModelLoaded = useH3DStore(currentModelLoadedAction)

  const p = useRef(0)
  const pMaxStep = useRef(25)

  const timer = useRef(null)
  useEffect(() => {
    if (!timer.current) {
      timer.current = setInterval(() => {
        if (p.current >= 100) {
          clearInterval(timer.current)
          timer.current = null
        }
        p.current = Math.max(pMaxStep.current, pMaxStep.current + 1)
        setProgress(p.current)
      }, 500)
    }
  }, [setProgress])

  const handleLoad0 = useCallback(() => {
    p.current = Math.max(p.current, 25)
    pMaxStep.current = 60
    setProgress(p.current)
    setL1(true)
  }, [setProgress])

  const handleLoad1 = useCallback(() => {
    p.current = Math.max(p.current, 50)
    pMaxStep.current = 60
    setProgress(p.current)
    setL2(true)
  }, [setProgress])

  const handleLoad2 = useCallback(() => {
    pMaxStep.current = 70
    setProgress(Math.max(p.current, 75))
    setL3(true)
  }, [setProgress])

  const handleLoad3 = useCallback(() => {
    setProgress(100)
    clearInterval(timer.current)
    timer.current = null
    setL4(true)
    mainModelLoaded()
  }, [setProgress, mainModelLoaded])

  const handleLoad4 = useCallback(() => {
    setL5(true)
  }, [])

  const handleLoad5 = useCallback(() => {
    setL6(true)
  }, [])

  const handleLoad6 = useCallback(() => {
    setL7(true)
  }, [])

  const handleLoad7 = useCallback(() => {
    setL8(true)
  }, [])

  const handleLoad8 = useCallback(() => {
    setL9(true)
  }, [])

  const handleLoad9 = useCallback(() => {
    currentModelLoaded()
  }, [currentModelLoaded])

  return promotion ? (
    <>
      <TowersBase
        onLoad={handleLoad0}
        nodes={nodes}
        envMap={envMap}
        backgroundHidden={backgroundHidden}
        {...props}
      />

      {l1 && (
        <MainTower
          onLoad={handleLoad1}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l2 && (
        <Torre4
          onLoad={handleLoad2}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l3 && (
        <Towers
          onLoad={handleLoad3}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l4 && (
        <Houses
          src="models/neinor_sky/model/houses.glb"
          name="Cajas Torre 3"
          onLoad={handleLoad4}
          envMap={envMap}
          withPrinex={promotion.withPrinex}
        />
      )}
      {l5 && (
        <Houses
          src="models/neinor_sky/model/housesTorre4.glb"
          name="Cajas Torre 4"
          onLoad={handleLoad5}
          envMap={envMap}
          withPrinex={promotion.withPrinex}
        />
      )}
      {l6 && (
        <EntornoGoogle
          onLoad={handleLoad6}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {/* <EntornoMobiliarioUrbano onLoad={handleLoad} {...props} /> */}
      {/* <EntornoMobiliarioTerraza onLoad={handleLoad} {...props} /> */}
      {l7 && (
        <Sky
          src={'models/neinor_sky/model/sky.jpg'}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          onLoad={handleLoad7}
          {...props}
        />
      )}
      {l8 && (
        <EntornoArbolesAcera
          onLoad={handleLoad8}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {/* <EntornoEdificiosGoogle onLoad={handleLoad} {...props} /> */}
      {l9 && (
        <EntornoObjects
          onLoad={handleLoad9}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
    </>
  ) : null
}
export default NeinorSky
