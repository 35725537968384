import { styled } from '@mui/material/styles'

import BookButton from './BookButton'
import HouseDetails from './HouseDetails'
import Viewer from './Viewer'
import { getCurrentPromotion } from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'

const Root = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.overlay + 100,
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  paddingBottom: 40,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexFlow: 'column',
  backgroundColor: theme.palette.primary.main
}))

const PlanView = () => {
  const promotion = useH3DStore(getCurrentPromotion)

  return promotion ? (
    <Root>
      {promotion.withPrinex && (
        <BookButton src={'images/llaves2.png'} title="Reserva tu vivienda" />
      )}
      <HouseDetails />
      <Viewer />
    </Root>
  ) : null
}
export default PlanView
