import NeinorSky from './Models/NeinorSky/NeinorSky'
import NeinorSoto from './Models/NeinorSoto/NeinorSoto'

export const ModelGLTF = ({ id, envMap, visible }) => {
  switch (id) {
    case 'neinor_sky':
      return <NeinorSky envMap={envMap} visible={visible} />
    case 'neinor_soto':
      return <NeinorSoto envMap={envMap} visible={visible} />
    default:
      return null
  }
}
