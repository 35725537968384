import { useRef, useState, useEffect, useMemo } from 'react'
import { useCursor } from '@react-three/drei'
import { useGesture } from '@use-gesture/react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGraph } from '@react-three/fiber'

import {
  MAIN_MODE,
  backgroundActiveSelector,
  filterActiveSelector,
  setMainModeAction
} from '../../../modules/mainStore'
import {
  HOUSE_STATE_COLORS,
  setCurrentHouseByNodeIdAction
} from '../../../modules/promotionStore'
import useH3DStore from '../../../modules/useH3DStore'
import { getFilterHouses } from '../../../modules/filterStore'
import { Euler, Quaternion, Vector3 } from 'three'

const isObjectInTop = (objectId, intersections) => {
  for (const i of intersections) {
    if (i.object.visible && !i.object.userData.boundingBox) {
      return objectId === i.object.id
    }
  }
  return false
}

export const Houses = ({ src, name, envMap, onLoad, withPrinex }) => {
  const v3 = useRef(new Vector3())
  const q = useRef(new Quaternion())
  const euler = useRef(new Euler())

  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    gltfLoader.current.loadAsync(src).then((g) => {
      console.log(name)
      setScene(g.scenes[0])
    })
  }, [src, name])

  const { nodes: originalNodes } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  const backgroundActive = useH3DStore(backgroundActiveSelector)
  const filterActive = useH3DStore(filterActiveSelector)
  const filterHouses = useH3DStore(getFilterHouses)
  const filterNodes = useMemo(() => {
    return Object.values(originalNodes).reduce((acc, node) => {
      const house = filterHouses.find((h) => {
        return h.nodeId === node.name
      })
      //Calcula la posicion aqui, porque el nodo puede ser hijo de varios grupos
      //cada uno con su propia posicion, por lo que al final la posicion no es
      //la que trae node. Igual con rotation y scale
      node.getWorldPosition(v3.current)
      const position = v3.current.toArray()
      node.getWorldQuaternion(q.current)
      euler.current.setFromQuaternion(q.current)
      const rotation = euler.current.toArray()
      node.getWorldScale(v3.current)
      const scale = v3.current.toArray()
      acc.push({ node, house, position, rotation, scale })
      return acc
    }, [])
  }, [originalNodes, filterHouses])

  return filterActive && scene
    ? filterNodes.map((data) => (
        <House
          key={data.node.name}
          node={data.node}
          house={data.house}
          position={data.position}
          rotation={data.rotation}
          scale={data.scale}
          envMap={envMap}
          backgroundActive={backgroundActive}
          withPrinex={withPrinex}
        />
      ))
    : null
}

const House = ({
  node,
  position,
  rotation,
  scale,
  house,
  envMap,
  backgroundActive,
  withPrinex
}) => {
  const ref = useRef(null)
  const setCurrentHouseByNodeId = useH3DStore(setCurrentHouseByNodeIdAction)
  const setMainMode = useH3DStore(setMainModeAction)
  const [hovered, setHovered] = useState()
  const disabled = !Boolean(house)
  useCursor(hovered && !disabled)

  const color = HOUSE_STATE_COLORS[house?.rentState]
    ? HOUSE_STATE_COLORS[house?.rentState]?.color
    : { r: 1, g: 1, b: 1 }

  const bind = useGesture(
    {
      onMove: ({ event }) => {
        event.stopPropagation()
        if (isObjectInTop(ref.current?.id, event.intersections)) {
          setHovered(true)
        } else {
          setHovered(false)
        }
      },
      onDrag: ({ event, tap }) => {
        event.stopPropagation()
        if (tap) {
          if (
            !disabled &&
            isObjectInTop(ref.current?.id, event.intersections)
          ) {
            setCurrentHouseByNodeId(node.name)
            !withPrinex && setMainMode(MAIN_MODE.PLAN)
            event.stopPropagation()
          }
        }
      }
    },
    {
      drag: { filterTaps: true }
    }
  )

  return (
    <mesh
      {...bind()}
      ref={ref}
      key={node.name}
      geometry={node.geometry}
      position={position}
      rotation={rotation}
      scale={scale}
      renderOrder={1}
    >
      <meshStandardMaterial
        envMap={backgroundActive ? envMap : null}
        transparent={true}
        opacity={disabled ? 0 : hovered ? 0.7 : 0.5}
        color-r={color.r}
        color-g={color.g}
        color-b={color.b}
        emissive-r={color.r}
        emissive-g={color.g}
        emissive-b={color.b}
        roughness={1}
        metalness={0}
        castShadow={false}
      />
    </mesh>
  )
}
export default Houses
