import { useEffect, useState, useRef } from 'react'
import { useGraph } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export function EntornoGoogle({ backgroundHidden, onLoad, nodes, ...props }) {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    const urlGltf = 'models/neinor_soto/model/fondo_google.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      console.log('Fondo google')
      setScene(g.scenes[0])
    })
  }, [])

  const { nodes: originalNodes, materials } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return !backgroundHidden && scene && nodes ? (
    <group {...props} dispose={null}>
      <mesh
        // castShadow
        receiveShadow
        geometry={originalNodes.Fondo_google.geometry}
        material={materials.Google}
        // material-roughness={1}
        // material-envMapIntensity={0.0}
        // material-lightMapIntensity={1.0}
        material-metalness={0.7}
        position={[-9.2, -0.32, -7.11]}
      />
    </group>
  ) : null
}

// export const EntornoGoogle = ({ backgroundHidden, onLoad, ...props }) => {
//   const { scene } = useGLTF('models/neinor_soto/model/fondo_google.glb')
//   useEffect(() => {
//     if (scene) {
//       onLoad?.()
//     }
//   }, [onLoad, scene])
//   return backgroundHidden ? null : <primitive object={scene} {...props} />
// }
