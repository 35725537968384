import { forwardRef, useEffect, useRef, useState } from 'react'
import Material from '../../Material'
import { useGraph } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export const Edificios = forwardRef(
  ({ nodes, envMap, backgroundHidden, onLoad, ...props }, ref) => {
    const gltfLoader = useRef(new GLTFLoader())
    const [scene, setScene] = useState(null)

    useEffect(() => {
      const urlGltf = 'models/neinor_soto/model/soto_edificios4.glb'
      gltfLoader.current.loadAsync(urlGltf).then((g) => {
        console.log('Edificios')
        setScene(g.scenes[0])
      })
    }, [])

    const { nodes: originalNodes } = useGraph(scene)

    useEffect(() => {
      if (scene) {
        onLoad?.()
      }
    }, [scene, onLoad])

    return scene && nodes ? (
      <>
        <group {...props} dispose={null}>
          <group>
            <mesh
              name="Piscina"
              receiveShadow
              geometry={originalNodes.Piscina.geometry}
              position={[-35.98, -4.43, -28.83]}
            >
              <Material
                original={originalNodes.Piscina.material}
                modified={nodes.Piscina.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            {backgroundHidden && (
              <mesh
                name="Entorno"
                castShadow
                receiveShadow
                geometry={originalNodes.Entorno.geometry}
                position={[1.65, 0, 11.41]}
              >
                <Material
                  original={originalNodes.Entorno.material}
                  modified={nodes.Entorno.material}
                  backgroundHidden={backgroundHidden}
                  envMap={envMap}
                />
              </mesh>
            )}
            {/* <mesh
              name="Fondo"
              castShadow
              receiveShadow
              geometry={originalNodes.Fondo.geometry}
              position={[34.03, -1.25, -13.75]}
            >
              <Material
                original={originalNodes.Fondo.material}
                modified={nodes.Fondo.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh> */}
            <mesh
              name="Metales"
              castShadow
              receiveShadow
              geometry={originalNodes.Metales.geometry}
              position={[-34.62, -1.3, -28.36]}
            >
              <Material
                original={originalNodes.Metales.material}
                modified={nodes.Metales.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Pared_blanca"
              castShadow
              receiveShadow
              geometry={originalNodes['Pared_blanca'].geometry}
              position={[-0.1, -5.13, -17.72]}
            >
              <Material
                original={originalNodes['Pared_blanca'].material}
                modified={nodes['Pared_blanca'].material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Cristal"
              castShadow
              receiveShadow
              geometry={originalNodes.Cristal.geometry}
              position={[31.49, -1, -13.28]}
            >
              <Material
                original={originalNodes.Cristal.material}
                modified={nodes.Cristal.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Cemento"
              castShadow
              receiveShadow
              geometry={originalNodes.Cemento.geometry}
              position={[0, -3.8, -17.59]}
            >
              <Material
                original={originalNodes.Cemento.material}
                modified={nodes.Cemento.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="aplacado_planta_baja"
              castShadow
              receiveShadow
              geometry={originalNodes.aplacado_planta_baja.geometry}
              position={[-48.47, -0.59, -12.28]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[1, -1, 1]}
            >
              <Material
                original={originalNodes.aplacado_planta_baja.material}
                modified={nodes.aplacado_planta_baja.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>

            <mesh
              name="Barandillas001"
              castShadow
              receiveShadow
              geometry={originalNodes.Barandillas001.geometry}
              position={[0, 6.59, -13.29]}
            >
              <Material
                original={originalNodes.Barandillas001.material}
                modified={nodes.Barandillas001.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Aplacado"
              castShadow
              receiveShadow
              geometry={originalNodes.Aplacado.geometry}
              position={[0, -2.81, -13.84]}
            >
              <Material
                original={originalNodes.Aplacado.material}
                modified={nodes.Aplacado.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Piscina_bordillo"
              receiveShadow
              geometry={originalNodes.Piscina_bordillo.geometry}
              position={[0, -3.8, -17.59]}
            >
              <Material
                original={originalNodes.Piscina_bordillo.material}
                modified={nodes.Piscina_bordillo.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Tierra"
              receiveShadow
              geometry={originalNodes.Tierra.geometry}
              position={[0, -3.8, -17.59]}
            >
              <Material
                original={originalNodes.Tierra.material}
                modified={nodes.Tierra.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Solerias"
              castShadow
              receiveShadow
              geometry={originalNodes.Solerias.geometry}
              position={[0, -2.98, -13.77]}
            >
              <Material
                original={originalNodes.Solerias.material}
                modified={nodes.Solerias.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Chinos_cubiertas"
              castShadow
              receiveShadow
              geometry={originalNodes.Chinos_cubiertas.geometry}
              position={[0, 2.77, -13.81]}
            >
              <Material
                original={originalNodes.Chinos_cubiertas.material}
                modified={nodes.Chinos_cubiertas.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Cesped"
              receiveShadow
              geometry={originalNodes.Cesped.geometry}
              position={[-34.35, -1.22, -20.71]}
            >
              <Material
                original={originalNodes.Cesped.material}
                modified={nodes.Cesped.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
            <mesh
              name="Pared_gris"
              castShadow
              receiveShadow
              geometry={originalNodes.Pared_gris.geometry}
              position={[-2.52, -7.74, -17.56]}
            >
              <Material
                original={originalNodes.Pared_gris.material}
                modified={nodes.Pared_gris.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
          </group>
        </group>
      </>
    ) : null
  }
)
