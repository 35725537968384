import { useEffect, useRef, useState } from 'react'
import { useGraph } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Material from '../../Material'

export function EntornoAcera({
  nodes,
  backgroundHidden,
  envMap,
  onLoad,
  ...props
}) {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    const urlGltf = 'models/neinor_soto/model/acera.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      console.log('Acera')
      setScene(g.scenes[0])
    })
  }, [])

  const { nodes: originalNodes, materials } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return !backgroundHidden && scene && nodes ? (
    <group {...props} dispose={null}>
      <group position={[3.1, -2.18, -34.68]}>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera_1.geometry}
          //   material={materials.Acera1}
        >
          <Material
            original={originalNodes.Acera_1.material}
            modified={nodes.Cemento.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera_2.geometry}
          //   material={materials['Cesped ']}
        >
          <Material
            original={originalNodes.Acera_2.material}
            modified={nodes.Cesped.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera_3.geometry}
          //   material={materials.tierra}
        >
          <Material
            original={originalNodes.Acera_3.material}
            modified={nodes.Tierra.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera_4.geometry}
          material={materials.Bordillo2}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera_5.geometry}
          material={materials.Asfaltos}
        />
      </group>
    </group>
  ) : null
}

// export const EntornoAcera = ({ backgroundHidden, onLoad, ...props }) => {
//   const { scene } = useGLTF('models/neinor_soto/model/acera.glb')
//   useEffect(() => {
//     if (scene) {
//       onLoad?.()
//     }
//   }, [onLoad, scene])
//   return backgroundHidden ? null : <primitive object={scene} {...props} />
// }
