import { SimpleModel } from './SimpleModel'

export const Carpinterias = ({ nodes, envMap, onLoad }) => {
  return (
    <SimpleModel
      name="Carpinterias"
      src={'models/neinor_soto/model/Carpinterias.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible
    />
  )
}
