import { useEffect, useRef, useState } from 'react'
import { useGraph } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export const EntornoObjects = ({ onLoad }) => {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    console.log('Objects')
    const urlGltf = 'models/neinor_sky/model/objects.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      setScene(g.scenes[0])
    })
  }, [])

  const { nodes: originalNodes, materials } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return scene ? (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Coches.geometry}
        material={materials.AM_131_cars_078}
        position={[67.99, 0.5, 7.37]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(12)'].geometry}
        material={materials.AM_131_cars_078}
        position={[-7.82, 0.78, -98.37]}
        rotation={[-Math.PI, 0.06, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(23)'].geometry}
        material={materials.AM_131_cars_078}
        position={[205.36, 0.71, -28.9]}
        rotation={[0, -0.96, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(1)'].geometry}
        material={materials.AM_131_cars_079}
        position={[19.1, 0.53, 7.1]}
        rotation={[Math.PI, -1.54, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(2)'].geometry}
        material={materials.AM_131_cars_087}
        position={[51.52, 0.5, 7.38]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(13)'].geometry}
        material={materials.AM_131_cars_087}
        position={[-6.91, 0.73, -87.36]}
        rotation={[-Math.PI, 0.06, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(19)'].geometry}
        material={materials.AM_131_cars_087}
        position={[-7.29, 0.78, -31.59]}
        rotation={[0, -0.04, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(24)'].geometry}
        material={materials.AM_131_cars_087}
        position={[213.47, 0.72, -36.39]}
        rotation={[0, -0.96, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(3)'].geometry}
        material={materials.AM_131_cars_081}
        position={[26.17, 0.53, 7.32]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(4)'].geometry}
        material={materials.AM_131_cars_082}
        position={[54.11, 0.5, 7.49]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(14)'].geometry}
        material={materials.AM_131_cars_082}
        position={[-7.14, 0.73, -84.79]}
        rotation={[-Math.PI, 0.06, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(20)'].geometry}
        material={materials.AM_131_cars_082}
        position={[-7.54, 0.78, -12.94]}
        rotation={[0, -0.04, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(25)'].geometry}
        material={materials.AM_131_cars_082}
        position={[215.65, 0.72, -37.79]}
        rotation={[0, -0.96, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(5)'].geometry}
        material={materials.AM_131_cars_091}
        position={[15.9, 0.53, 6.81]}
        rotation={[Math.PI, -1.55, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(6)'].geometry}
        material={materials.AM_131_cars_092}
        position={[65.5, 0.5, 7.1]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(15)'].geometry}
        material={materials.AM_131_cars_092}
        position={[-7.42, 0.78, -100.84]}
        rotation={[-Math.PI, 0.06, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(26)'].geometry}
        material={materials.AM_131_cars_092}
        position={[203.17, 0.71, -27.69]}
        rotation={[0, -0.96, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(7)'].geometry}
        material={materials.AM_131_cars_093}
        position={[11.52, 0.53, 6.95]}
        rotation={[Math.PI, -1.52, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(8)'].geometry}
        material={materials.AM_131_cars_095}
        position={[56.68, 0.5, 7.57]}
        rotation={[Math.PI, -1.47, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(16)'].geometry}
        material={materials.AM_131_cars_095}
        position={[-7.36, 0.73, -82.22]}
        rotation={[-Math.PI, 0.15, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(21)'].geometry}
        material={materials.AM_131_cars_095}
        position={[-7.36, 0.78, -15.51]}
        rotation={[0, -0.14, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(27)'].geometry}
        material={materials.AM_131_cars_095}
        position={[217.81, 0.72, -39.2]}
        rotation={[0, -1.06, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(11)'].geometry}
        material={materials.AM_131_cars_013}
        position={[62.8, 0.5, 6.9]}
        rotation={[0, -0.01, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(18)'].geometry}
        material={materials.AM_131_cars_013}
        position={[-7.09, 0.78, -103.53]}
        rotation={[Math.PI, -1.52, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(29)'].geometry}
        material={materials.AM_131_cars_013}
        position={[200.85, 0.71, -26.3]}
        rotation={[0, 0.61, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(9)'].geometry}
        material={materials.AM_131_cars_096}
        position={[59.6, 0.5, 7.28]}
        rotation={[Math.PI, -1.57, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(17)'].geometry}
        material={materials.AM_131_cars_096}
        position={[-7.21, 0.73, -79.29]}
        rotation={[-Math.PI, 0.06, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(22)'].geometry}
        material={materials.AM_131_cars_096}
        position={[-7.56, 0.78, -18.44]}
        rotation={[0, -0.04, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(28)'].geometry}
        material={materials.AM_131_cars_096}
        position={[220.02, 0.72, -41.12]}
        rotation={[0, -0.96, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes['Coches_(10)'].geometry}
        material={materials.AM_131_cars_101}
        position={[22.95, 0.72, 7.41]}
        rotation={[0, -1.03, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Platanera_triple_low.geometry}
        material={materials.AM_131_069_elaeis_guineensis}
        position={[48.76, 0.99, -26.44]}
        rotation={[-Math.PI, 0.38, -Math.PI]}
        scale={0.67}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_baja_low001.geometry}
        material={materials.AM_131_078_Livistona_merrillii_Color}
        position={[3.16, -0.24, -105.98]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_datilera_low.geometry}
        material={materials.AM_131_dactylifera}
        position={[3.81, 1.89, -13.08]}
        scale={0.76}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_datilera_low001.geometry}
        material={materials.AM_131_dactylifera}
        position={[9.69, 1.54, -4.69]}
        rotation={[Math.PI, -1.46, Math.PI]}
        scale={0.76}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_datilera_low002.geometry}
        material={materials.AM_131_dactylifera}
        position={[9.52, 1.54, -13.23]}
        rotation={[Math.PI, -1.46, Math.PI]}
        scale={0.59}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_baja_low.geometry}
        material={materials.AM_131_074_Phoenix_canariensis}
        position={[60.83, 1.22, -28.5]}
        scale={1.21}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_baja_low002.geometry}
        material={materials.AM_131_074_Phoenix_canariensis}
        position={[85.62, 1.22, -17.89]}
        scale={1.21}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_baja_low003.geometry}
        material={materials.AM_131_074_Phoenix_canariensis}
        position={[141.14, 1.22, -26.59]}
        scale={1.21}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_baja_2_low.geometry}
        material={materials.AM_131_066_trachycaprus2}
        position={[3.03, 1.15, -23.56]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.palmera_baja_2_low001.geometry}
        material={materials.AM_131_066_trachycaprus2}
        position={[17.43, 1.15, -3.87]}
      />
      <group position={[46.18, 5.59, -101.99]}>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[52.28, 3.99, -92.02]}
        rotation={[Math.PI, -1.46, Math.PI]}
        scale={0.74}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group position={[195.04, 5.72, -71.39]}>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande002_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande002_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[200.89, 3.99, -60.35]}
        rotation={[0, 1.57, 0]}
        scale={0.74}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande003_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande003_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[192.42, 3.2, -61.2]}
        rotation={[0, 1.57, 0]}
        scale={0.6}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande004_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande004_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[105.6, 5.72, -78.03]}
        rotation={[Math.PI, -0.18, Math.PI]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande005_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande005_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[97.9, 3.99, -87.87]}
        rotation={[0, -1.4, 0]}
        scale={0.74}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande006_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande006_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[106.39, 3.2, -88.52]}
        rotation={[0, -1.4, 0]}
        scale={0.6}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande007_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande007_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[15.77, 5.59, -75.22]}
        rotation={[-Math.PI, 0.51, -Math.PI]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande008_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande008_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
      <group
        position={[15.26, 3.99, -86.9]}
        rotation={[0, 0.96, 0]}
        scale={0.74}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande008_1.geometry}
          material={materials['AM_131_053_acer palmatum_Bark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Arbol_grande008_2.geometry}
          material={materials['AM_131_053_acer palmatum']}
        />
      </group>
    </group>
  ) : null
}
