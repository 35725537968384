import { useGraph } from '@react-three/fiber'
import { useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export function EntornoEdificios({
  backgroundHidden,
  onLoad,
  nodes,
  ...props
}) {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    const urlGltf = 'models/neinor_soto/model/edificios.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      console.log('Entorno edificios')
      setScene(g.scenes[0])
    })
  }, [])

  const { nodes: originalNodes, materials } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return !backgroundHidden && scene && nodes ? (
    <group {...props} dispose={null}>
      <mesh
        // castShadow
        receiveShadow
        geometry={originalNodes.Edificios.geometry}
        material={materials.Blanco}
        material-transparent
        material-opacity={0.4}
        position={[62.53, -3.07, -56.39]}
        rotation={[0, 0, -Math.PI]}
        scale={[1, -1, 1]}
      />
    </group>
  ) : null
}
