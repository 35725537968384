import { useEffect } from 'react'
import { useFBX } from '@react-three/drei'
import { useGraph } from '@react-three/fiber'
import useH3DStore from '../../modules/useH3DStore'
import { currentModelLoadedAction } from '../../modules/modelStore'

// import { NeinorFBX } from './Neinor/NeinorFBX'

export const ModelFBX = ({ id, src, envMap }) => {
  const scene = useFBX(src)
  const { nodes, materials } = useGraph(scene)
  const currentModelLoaded = useH3DStore(currentModelLoadedAction)

  useEffect(() => {
    if (scene) {
      currentModelLoaded()
    }
  }, [scene, currentModelLoaded])

  switch (id) {
    case 'neinor':
    // return <NeinorFBX nodes={nodes} materials={materials} envMap={envMap} />
    default:
      return null
  }
}
export default ModelFBX
