import { useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export function EntornoGoogle({
  nodes,
  envMap,
  backgroundHidden,
  onLoad,
  ...props
}) {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    console.log('Plano')
    const urlGltf = 'models/neinor_sky/model/plane.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      setScene(g.scenes[0])
    })
  }, [])

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return backgroundHidden || !scene ? null : (
    <primitive object={scene} {...props} />
  )
}
