import { useEffect } from 'react'
import { useTexture } from '@react-three/drei'
import * as THREE from 'three'

const WithTexture = ({ original, modified, backgroundHidden, envMap }) => {
  const texture = useTexture(modified.fullMap)

  useEffect(() => {
    if (texture) {
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      texture.needsUpdate = true
    }
  }, [texture])

  return (
    <meshStandardMaterial
      map={texture}
      envMap={modified.envMap && !backgroundHidden ? envMap : null}
      transparent={modified.transparent}
      opacity={modified.opacity}
      color-r={modified.color.r}
      color-g={modified.color.g}
      color-b={modified.color.b}
      emissive-r={modified.emissive.r}
      emissive-g={modified.emissive.g}
      emissive-b={modified.emissive.b}
      roughness={modified.roughness}
      metalness={modified.metalness}
      castShadow={true}
    />
  )
}
const WithoutTexture = ({ original, modified, backgroundHidden, envMap }) => {
  return (
    <meshStandardMaterial
      map={original.map}
      envMap={modified.envMap && !backgroundHidden ? envMap : null}
      transparent={modified.transparent}
      opacity={modified.opacity}
      color-r={modified.color.r}
      color-g={modified.color.g}
      color-b={modified.color.b}
      emissive-r={modified.emissive.r}
      emissive-g={modified.emissive.g}
      emissive-b={modified.emissive.b}
      roughness={modified.roughness}
      metalness={modified.metalness}
      castShadow={true}
    />
  )
}

export const Material = ({ original, modified, backgroundHidden, envMap }) => {
  // console.log(original, modified)
  return modified ? (
    modified.fullMap ? (
      <WithTexture
        original={original}
        modified={modified}
        backgroundHidden={backgroundHidden}
        envMap={envMap}
      />
    ) : (
      <WithoutTexture
        original={original}
        modified={modified}
        backgroundHidden={backgroundHidden}
        envMap={envMap}
      />
    )
  ) : null
}

export default Material
