import { styled } from '@mui/material/styles'
import { isMobile } from '../../modules/mainStore'
import {
  getCurrentHouse,
  getCurrentPromotion
} from '../../modules/promotionStore'
import useH3DStore from '../../modules/useH3DStore'
import Details from '../HouseDetails/Details'
import Price from '../HouseDetails/Price'
import Title from '../HouseDetails/Title'
import BackButton from './BackButton'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mobile'
})(({ theme, mobile }) => ({
  zIndex: theme.zIndex.overlay,
  position: 'absolute',
  bottom: mobile ? 0 : 40,
  right: 0,
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'end',
  padding: mobile ? 24 : 40
}))

export const HouseDetails = ({ onClick }) => {
  const promotion = useH3DStore(getCurrentPromotion)
  const mobile = useH3DStore(isMobile)
  const currentHouse = useH3DStore(getCurrentHouse)

  return promotion ? (
    <Root mobile={mobile} onClick={onClick}>
      {promotion.withPrinex && (
        <>
          <Title textAlign={'right'} mobile={mobile}>
            Portal {currentHouse.portal}
          </Title>
          <Details textAlign={'right'} mobile={mobile} />
          <Price
            textAlign={'right'}
            mobile={mobile}
            price={currentHouse.rentPrice}
          />
        </>
      )}
      <BackButton mobile={mobile} />
    </Root>
  ) : null
}
export default HouseDetails
