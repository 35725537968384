import * as React from 'react'

const EdificiosIcon = ({
  primaryColor = '#000',
  secondaryColor = '#F00',
  ...props
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" {...props}>
      <path
        style={{
          fill: 'red',
          stroke: primaryColor,
          strokeWidth: 3.26929134,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
          strokeDasharray: 'none'
        }}
        d="M18.591 107.144h92.222"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 3.26929134,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
          strokeDasharray: 'none'
        }}
        d="m101.968 107.07-.087-64H74.638v0"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 3.26929134,
          strokeLinecap: 'butt',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
          strokeDasharray: 'none'
        }}
        d="m66.768 107.243.259-86.14H27.33l-.087 86.054"
      />
      <path
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 3.26929134,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1,
          strokeMiterlimit: 4,
          strokeDasharray: 'none'
        }}
        d="M43.787 107.144V92.772h7.278l-.062 14.372"
      />
      <path
        style={{
          fill: 'red',
          fillOpacity: 0,
          stroke: primaryColor,
          strokeWidth: 4,
          strokeOpacity: 1
        }}
        fill="none"
        d="M36.387 32.822h.55v1.712h-.55zM47.854 32.822h.55v1.712h-.55zM58.373 32.822h.55v1.712h-.55zM36.433 43.114h.55v1.712h-.55zM47.9 43.114h.55v1.712h-.55zM58.418 43.114h.55v1.712h-.55zM36.25 53.45h.55v1.712h-.55zM47.716 53.45h.55v1.712h-.55zM58.235 53.45h.55v1.712h-.55zM36.311 63.357h.55v1.712h-.55zM47.777 63.357h.55v1.712h-.55zM58.296 63.357h.55v1.712h-.55zM36.25 74.059h.55v1.712h-.55zM47.716 74.059h.55v1.712h-.55zM58.235 74.059h.55v1.712h-.55zM36.189 83.966h.55v1.712h-.55zM47.655 83.966h.55v1.712h-.55zM58.174 83.966h.55v1.712h-.55zM78.814 86.473h.55v1.712h-.55zM89.975 86.473h.55v1.712h-.55zM78.905 76.138h.55v1.712h-.55zM90.066 76.138h.55v1.712h-.55zM78.982 65.895h.55v1.712h-.55zM90.143 65.895h.55v1.712h-.55zM79.074 55.559h.55v1.712h-.55zM90.234 55.559h.55v1.712h-.55z"
      />
      <path
        style={{
          fill: secondaryColor,
          fillOpacity: 1,
          stroke: 'none',
          strokeWidth: '1.03627px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeOpacity: 1
        }}
        d="m79.395 19.292 9.254 15.735 9.254-15.913z"
      />
    </svg>
  )
}

export default EdificiosIcon
