import { useCallback, useEffect, useRef, useState } from 'react'

import { Edificios } from './Edificios'
import {
  currentModelLoadedAction,
  getCurrentModelNodesById,
  mainModelLoadedAction
} from '../../../../modules/modelStore'
import {
  backgroundActiveSelector,
  setProgressAction
} from '../../../../modules/mainStore'
import useH3DStore from '../../../../modules/useH3DStore'
import { Houses } from '../Houses'

import { EntornoEdificios } from './EntornoEdificios'
import { EntornoAcera } from './EntornoAcera'
import { EntornoGoogle } from './EntornoGoogle'
import { Sky } from '../Sky'
import {
  Arboles,
  ArbolesParque,
  Coches,
  Farolas,
  NombreCalles,
  VegetacionMuebles
} from './Objects'
import { Carpinterias } from './Carpinterias'
import { getCurrentPromotion } from '../../../../modules/promotionStore'

export const NeinorSoto = ({ envMap, visible, ...props }) => {
  const [l1, setL1] = useState(false)
  const [l2, setL2] = useState(false)
  const [l3, setL3] = useState(false)
  const [l4, setL4] = useState(false)
  const [l5, setL5] = useState(false)
  const [l6, setL6] = useState(false)
  const [l7, setL7] = useState(false)
  const [l8, setL8] = useState(false)
  const [l9, setL9] = useState(false)
  const [l10, setL10] = useState(false)
  const [l11, setL11] = useState(false)
  const [l12, setL12] = useState(false)
  const promotion = useH3DStore(getCurrentPromotion)
  const nodes = useH3DStore(getCurrentModelNodesById)
  const backgroundHidden = !useH3DStore(backgroundActiveSelector)
  const setProgress = useH3DStore(setProgressAction)
  const mainModelLoaded = useH3DStore(mainModelLoadedAction)
  const currentModelLoaded = useH3DStore(currentModelLoadedAction)

  const p = useRef(0)
  const pMaxStep = useRef(33)

  const timer = useRef(null)
  useEffect(() => {
    if (!timer.current) {
      // console.log('******************timer**********************')
      timer.current = setInterval(() => {
        if (p.current >= 100) {
          clearInterval(timer.current)
          timer.current = null
        }
        p.current = Math.max(pMaxStep.current, pMaxStep.current + 1)
        setProgress(p.current)
      }, 500)
    }
  }, [setProgress])

  const handleLoad0 = useCallback(() => {
    p.current = Math.max(p.current, 33)
    pMaxStep.current = 60
    setProgress(p.current)
    setL1(true)
  }, [setProgress])

  const handleLoad1 = useCallback(() => {
    p.current = Math.max(p.current, 33)
    pMaxStep.current = 60
    setProgress(p.current)
    setL2(true)
  }, [setProgress])

  const handleLoad2 = useCallback(() => {
    setProgress(100)
    clearInterval(timer.current)
    timer.current = null
    setL3(true)
    mainModelLoaded()
  }, [setProgress, mainModelLoaded])

  const handleLoad3 = useCallback(() => {
    setL4(true)
  }, [])

  const handleLoad4 = useCallback(() => {
    setL5(true)
  }, [])

  const handleLoad5 = useCallback(() => {
    setL6(true)
  }, [])
  const handleLoad6 = useCallback(() => {
    setL7(true)
  }, [])
  const handleLoad7 = useCallback(() => {
    setL8(true)
  }, [])
  const handleLoad8 = useCallback(() => {
    setL9(true)
  }, [])
  const handleLoad9 = useCallback(() => {
    setL10(true)
  }, [])
  const handleLoad10 = useCallback(() => {
    setL11(true)
  }, [])
  const handleLoad11 = useCallback(() => {
    setL12(true)
  }, [])
  const handleLoad12 = useCallback(() => {
    currentModelLoaded()
  }, [currentModelLoaded])

  return promotion ? (
    <>
      <Edificios
        onLoad={handleLoad0}
        nodes={nodes}
        envMap={envMap}
        backgroundHidden={backgroundHidden}
        {...props}
      />
      {l1 && (
        <Carpinterias
          onLoad={handleLoad1}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l2 && (
        <EntornoAcera
          onLoad={handleLoad2}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l3 && (
        <EntornoEdificios
          onLoad={handleLoad3}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l4 && (
        <EntornoGoogle
          onLoad={handleLoad4}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l5 && (
        <Sky
          src={'models/neinor_soto/model/sky.jpg'}
          onLoad={handleLoad5}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l6 && (
        <Houses
          src="models/neinor_soto/model/cajas2.glb"
          name="Cajas"
          onLoad={handleLoad6}
          envMap={envMap}
          withPrinex={promotion.withPrinex}
        />
      )}
      {l7 && (
        <VegetacionMuebles
          onLoad={handleLoad7}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l8 && (
        <Arboles
          onLoad={handleLoad8}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l9 && (
        <NombreCalles
          onLoad={handleLoad9}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l10 && (
        <ArbolesParque
          onLoad={handleLoad10}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l11 && (
        <Farolas
          onLoad={handleLoad11}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
      {l12 && (
        <Coches
          onLoad={handleLoad12}
          nodes={nodes}
          envMap={envMap}
          backgroundHidden={backgroundHidden}
          {...props}
        />
      )}
    </>
  ) : null
}
export default NeinorSoto
