import { useEffect, useRef, useState } from 'react'

import Material from '../../Material'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGraph } from '@react-three/fiber'

export const Torre4 = ({
  nodes,
  envMap,
  backgroundHidden,
  onLoad,
  ...props
}) => {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    console.log('Torre4')
    const urlGltf = 'models/neinor_sky/model/torre4.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      setScene(g.scenes[0])
    })
  }, [])

  const { nodes: originalNodes, materials } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])
  //   return <primitive object={scene} {...props} />

  //   const { nodes, materials } = useGLTF('/torre4.glb')

  return scene ? (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Cristal.geometry}
        position={[163.15, 32.96, -20.83]}
      >
        <Material
          original={originalNodes.Cristal.material}
          modified={nodes.Cristal.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Pared_gris.geometry}
        position={[163.39, 35.1, -20.26]}
      >
        <Material
          original={originalNodes.Pared_gris.material}
          modified={nodes.Pared_gris.material}
          backgroundHidden={backgroundHidden}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Carpinteria.geometry}
        material={materials.Metal}
        position={[163.39, 34.4, -20.5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Pared_blanca.geometry}
        position={[163.39, 35.22, -20.26]}
      >
        <Material
          original={originalNodes.Pared_blanca.material}
          modified={nodes.Pared_blanca.material}
          backgroundHidden={backgroundHidden}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Barandillas.geometry}
        position={[163.15, 32.96, -20.83]}
      >
        <Material
          original={originalNodes.Barandillas.material}
          modified={nodes.Barandillas.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Madera.geometry}
        position={[163.21, 53.83, -20.87]}
      >
        <Material
          original={materials.chinos2}
          modified={nodes.Soleria.material}
          backgroundHidden={backgroundHidden}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Chinos.geometry}
        position={[163.21, 53.83, -20.87]}
      >
        <Material
          original={materials.chinos2}
          modified={nodes.Soleria.material}
          backgroundHidden={backgroundHidden}
          envMap={envMap}
        />
      </mesh>
    </group>
  ) : null
}
