import { useRef } from 'react'

import useH3DStore from '../../../modules/useH3DStore'
import { getCurrentModel } from '../../../modules/modelStore'
import DirectionalLightBase from './DirectionalLightBase'

export const DirectionalLight = () => {
  const model = useH3DStore(getCurrentModel)
  const lightRef = useRef()

  return (
    <DirectionalLightBase
      ref={lightRef}
      intensity={model.directionalLight.intensity}
      color={model.directionalLight.color}
      castShadow={model.directionalLight.shadow}
      bias={model.directionalLight.bias}
      normalBias={model.directionalLight.normalBias}
      radius={model.directionalLight.radius}
      mapWidth={model.directionalLight.mapSize?.width}
      mapHeight={model.directionalLight.mapSize?.height}
      far={model.directionalLight.camera.far}
      near={model.directionalLight.camera.near}
      left={model.directionalLight.camera.left}
      right={model.directionalLight.camera.right}
      top={model.directionalLight.camera.top}
      bottom={model.directionalLight.camera.bottom}
      position={model.directionalLight.position}
      targetPosition={model.directionalLight.target}
    />
  )
}
export default DirectionalLight
