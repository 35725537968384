import { useCallback, useEffect, useRef, useState } from 'react'
import { useFrame, useGraph } from '@react-three/fiber'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Material from '../../Material'

export const Towers = ({ nodes, envMap, backgroundHidden, onLoad }) => {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    console.log('Torres transparentes')
    const urlGltf = 'models/neinor_sky/model/towers.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      setScene(g.scenes[0])
    })
  }, [])

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  const { nodes: originalNodes } = useGraph(scene)
  const raycaster = useRef(new THREE.Raycaster())
  const v3 = useRef(new THREE.Vector3())

  const ghotsNodes = [
    originalNodes.Torre4,
    originalNodes.Plane008,
    originalNodes.Plane009
  ]

  useFrame(({ camera, controls }) => {
    if (!backgroundHidden && scene) {
      // console.log(controls)
      v3.current.copy(controls.target)
      v3.current.set(
        v3.current.x - camera.position.x,
        v3.current.y - camera.position.y,
        v3.current.z - camera.position.z
      )
      v3.current.normalize()

      raycaster.current.set(camera.position, v3.current)
      const intersects = raycaster.current.intersectObjects(ghotsNodes)
      // ghotsNodes.forEach((node) => {
      //   node.visible = true
      // })
      ghotsNodes.forEach((node) => {
        node.visible = !Boolean(
          intersects.find((i) => {
            return i.object === node && i.distance < 50
          })
        )
        // node.visible = true
      })

      // if (intersects.length === 0) {
      //   ghotsNodes.forEach((node) => (node.visible = true))
      // } else {
      //   intersects.forEach((i) => {
      //     i.object.visible = i.distance > 50
      //   })
      // }
    }
  })

  const handleFakePointerEnter = useCallback((e) => {}, [])
  /*
  NOTA: Dejar las funciones en onPointerEnter, hacen falta para que los hotspots
  detecten si estan detras de las torres cuando el puntero pasa sobre ellos.
  Si se quitan intersections en el evento del hotspot no detecta la torre
  */
  /*
  return originalNodes && nodes ? (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Torre4.geometry}
        position={[160.89, 6.39, -10.36]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      >
        <Material
          original={originalNodes.Torre4.material}
          modified={nodes.Torre4.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Plane008.geometry}
        position={[82.99, 6.67, -2.94]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      >
        <Material
          original={originalNodes.Plane008.material}
          modified={nodes.Plane008.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={originalNodes.Plane009.geometry}
        position={[19.09, 6.66, -23.46]}
        rotation={[Math.PI / 2, 0, 0]}
      >
        <Material
          original={originalNodes.Plane009.material}
          modified={nodes.Plane009.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </mesh>
    </group>
  ) : null
  */

  return !scene || backgroundHidden ? null : (
    <>
      {/* <primitive
        object={originalNodes.Torre4}
        // onPointerEnter={handleFakePointerEnter}
        castShadow
        receiveShadow
        renderOrder={100}
      >
        <Material
          original={originalNodes.Torre4.material}
          modified={nodes.Torre4.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </primitive> */}
      <primitive
        object={originalNodes.Plane008}
        // onPointerEnter={handleFakePointerEnter}
        castShadow
        receiveShadow
        renderOrder={100}
      >
        <Material
          original={originalNodes.Plane008.material}
          modified={nodes.Plane008.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </primitive>
      <primitive
        object={originalNodes.Plane009}
        // onPointerEnter={handleFakePointerEnter}
        castShadow
        receiveShadow
        renderOrder={100}
      >
        <Material
          original={originalNodes.Plane009.material}
          modified={nodes.Plane009.material}
          backgroundHidden={false}
          envMap={envMap}
        />
      </primitive>
    </>
  )
}
