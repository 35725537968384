import { forwardRef, useMemo, useRef } from 'react'
import * as THREE from 'three'

export const BoundingBox = forwardRef(
  ({ node, materialsProps, ...props }, ref) => {
    const box = useRef(new THREE.Box3())
    const { position, size } = useMemo(() => {
      box.current.setFromObject(node)
      const size = [
        box.current.max.x - box.current.min.x,
        box.current.max.y - box.current.min.y,
        box.current.max.z - box.current.min.z
      ]
      return {
        position: [
          box.current.min.x + size[0] / 2,
          box.current.min.y + size[1] / 2,
          box.current.min.z + size[2] / 2
        ],
        size
      }
    }, [node])

    return (
      <mesh position={position} ref={ref} {...props}>
        <boxGeometry args={size} />
        <meshStandardMaterial
          color={'#00FF00'}
          transparent={true}
          opacity={0.5}
          {...materialsProps}
        />
      </mesh>
    )
  }
)
