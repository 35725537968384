import { useTheme } from '@mui/material/styles'
import { useCallback } from 'react'
import {
  backgroundActiveSelector,
  isMobile,
  setBackgroundActiveAction
} from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import EdificiosIcon from '../Icons/EdificiosIcon'
import Button from './Button'

export const BackgroundButton = () => {
  const mobile = useH3DStore(isMobile)
  const backgroundActive = useH3DStore(backgroundActiveSelector)
  const setBackgroundActive = useH3DStore(setBackgroundActiveAction)
  const theme = useTheme()

  const handleBackground = useCallback(() => {
    setBackgroundActive(!backgroundActive)
  }, [setBackgroundActive, backgroundActive])

  return (
    <Button
      title="Entorno"
      selected={!backgroundActive}
      mobile={mobile}
      onClick={handleBackground}
    >
      <EdificiosIcon
        width={'auto'}
        height={'100%'}
        primaryColor={
          !backgroundActive
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText
        }
        secondaryColor={
          !backgroundActive
            ? theme.palette.primary.main
            : theme.palette.secondary.main
        }
      />
    </Button>
  )
}
export default BackgroundButton
