import { useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export const SimpleModel = ({
  name,
  nodes,
  envMap,
  onLoad,
  src,
  visible = true,
  ...props
}) => {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    const urlGltf = src
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      setScene(g.scenes[0])
      console.log(name)
    })
  }, [src, name])

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return scene && nodes && visible ? (
    <primitive object={scene} {...props} />
  ) : null
}
