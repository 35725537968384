import { useEffect, useRef, useState } from 'react'
import { useGraph } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Material from '../../Material'

export function TowersBase({
  nodes,
  backgroundHidden,
  envMap,
  onLoad,
  ...props
}) {
  const gltfLoader = useRef(new GLTFLoader())
  const [scene, setScene] = useState(null)

  useEffect(() => {
    console.log('TowerBase')
    const urlGltf = 'models/neinor_sky/model/ZonasComunes.glb'
    gltfLoader.current.loadAsync(urlGltf).then((g) => {
      setScene(g.scenes[0])
    })
  }, [])
  const { nodes: originalNodes } = useGraph(scene)

  useEffect(() => {
    if (scene) {
      onLoad?.()
    }
  }, [scene, onLoad])

  return scene ? (
    <>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_cesped.geometry}
          position={[99.43, 1.19, -19.96]}
        >
          <Material
            original={originalNodes.Entorno_cesped.material}
            modified={nodes.Entorno_cesped.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_Agua_piscina.geometry}
          position={[74.79, 1.46, -20.66]}
        >
          <Material
            original={originalNodes.Entorno_Agua_piscina.material}
            modified={nodes.Entorno_Agua_piscina.material}
            backgroundHidden={false}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_chinos.geometry}
          position={[92.69, 5.47, -19.98]}
        >
          <Material
            original={originalNodes.Entorno_chinos.material}
            modified={nodes.Entorno_chinos.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_piscina.geometry}
          position={[99.58, -3.41, -20.03]}
        >
          <Material
            original={originalNodes.Entorno_piscina.material}
            modified={nodes.Entorno_piscina.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_madera.geometry}
          position={[137.24, 1.89, -29.92]}
        >
          <Material
            original={originalNodes.Entorno_madera.material}
            modified={nodes.Entorno_madera.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_barandillas.geometry}
          position={[19.83, 3.13, -15.86]}
        >
          <Material
            original={originalNodes.Entorno_barandillas.material}
            modified={nodes.Entorno_barandillas.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_carpinteria.geometry}
          position={[24.48, 2.92, -23.53]}
          rotation={[Math.PI / 2, 0, 0]}
        >
          <Material
            original={originalNodes.Entorno_carpinteria.material}
            modified={nodes.Entorno_carpinteria.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Entorno_pared_blanca.geometry}
          position={[99.08, -3.13, -20.03]}
        >
          <Material
            original={originalNodes.Entorno_pared_blanca.material}
            modified={nodes.Entorno_pared_blanca.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Cesped.geometry}
          position={[13.46, 0, -85.74]}
        >
          <Material
            original={originalNodes.Cesped.material}
            modified={nodes.Cesped.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera_bordillo.geometry}
          position={[85.16, -0.43, -61.75]}
        >
          <Material
            original={originalNodes.Acera_bordillo.material}
            modified={nodes.Acera_bordillo.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Asfalto.geometry}
          position={[205.74, 0.08, -84.75]}
        >
          <Material
            original={originalNodes.Asfalto.material}
            modified={nodes.Asfalto.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Box002.geometry}
          material={originalNodes.Box002.material}
          position={[99.78, 1.81, -1.15]}
          rotation={[Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={originalNodes.Acera.geometry}
          position={[85.16, 0, -61.75]}
        >
          <Material
            original={originalNodes.Acera.material}
            modified={nodes.Acera.material}
            backgroundHidden={backgroundHidden}
            envMap={envMap}
          />
        </mesh>
      </group>
    </>
  ) : null
}
