import { useCallback } from 'react'
import { styled, alpha } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import CloseButton from '../Common/CloseButton'
import FilterSlider from '../Common/FilterSlider'
import RoomCheckbox from './RoomCheckbox'
import Cell from './Cell'

import {
  boundsSelector,
  filterActiveSelector,
  setFilterActiveAction
} from '../../modules/mainStore'
import useH3DStore from '../../modules/useH3DStore'
import {
  currentHouseSelector,
  getCurrentPromotion
} from '../../modules/promotionStore'
import {
  filterPriceRangeSelector,
  filterRoomOptionsSelector,
  setPriceRangeAction,
  toggleRoomOptionAction
} from '../../modules/filterStore'

const Root = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'narrow'
})(({ theme, narrow }) => ({
  fontFamily: `NeinorNormal ,Roboto, Helvetica, Arial, sans-serif`,
  position: 'absolute',
  zIndex: theme.zIndex.overlay,
  bottom: 12,
  left: '50%',
  transform: 'translate(-50%,0)',
  boxSizing: 'border-box',
  padding: 4,
  width: 800,
  maxWidth: '96%',
  height: narrow ? 200 : 120,
  display: 'flex',
  flexFlow: narrow ? 'column' : 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.primary.light, 0.9),
  borderRadius: 0
}))

const Price = ({ sliderPriceRange, filterPriceRange, narrow, onChange }) => {
  const handleChange = useCallback(
    (e, newValue) => {
      onChange?.(newValue[0], newValue[1])
    },
    [onChange]
  )

  return (
    <Cell title={'Precio'} vertical={narrow}>
      <FilterSlider
        min={sliderPriceRange[0]}
        max={sliderPriceRange[1]}
        range={filterPriceRange}
        step={10}
        unit={'€'}
        onRangeChange={handleChange}
      />
    </Cell>
  )
}

const Rooms = ({
  options, //[{id,nRooms}]
  selected, //[id,...]
  narrow,
  onChange
}) => {
  return (
    <Cell title={'Dormitorios'} vertical={narrow}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxSizing: 'border-box',
          width: '100%',
          height: '100%',
          padding: '0 24px'
        }}
      >
        {options.map((roomOption) => (
          <RoomCheckbox
            key={roomOption.id}
            label={roomOption.nRooms}
            checked={selected.includes(roomOption.id)}
            onCheck={() => onChange?.(roomOption.id)}
          />
        ))}
      </div>
    </Cell>
  )
}

export const Filter = () => {
  const promotion = useH3DStore(getCurrentPromotion)
  const currentHouse = useH3DStore(currentHouseSelector)
  const filterActive = useH3DStore(filterActiveSelector)
  const setFilterActive = useH3DStore(setFilterActiveAction)
  const filterRoomOptions = useH3DStore(filterRoomOptionsSelector)
  const filterPriceRange = useH3DStore(filterPriceRangeSelector)
  const toggleRoomOption = useH3DStore(toggleRoomOptionAction)
  const setPriceRange = useH3DStore(setPriceRangeAction)
  const bounds = useH3DStore(boundsSelector)
  const narrow = bounds?.width > 0 && bounds?.width < 720

  const handleFilter = useCallback(() => {
    setFilterActive(false)
  }, [setFilterActive])

  return promotion?.withPrinex && !currentHouse && filterActive ? (
    <Root elevation={4} narrow={narrow}>
      <CloseButton onClick={handleFilter} />

      <Rooms
        options={promotion.data.roomOptions}
        selected={filterRoomOptions}
        narrow={narrow}
        onChange={toggleRoomOption}
      />
      <Price
        sliderPriceRange={promotion.data.priceRange}
        filterPriceRange={filterPriceRange}
        onChange={setPriceRange}
        narrow={narrow}
      />
    </Root>
  ) : null
}
export default Filter
