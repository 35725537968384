import { forwardRef, useEffect, useRef, useState } from 'react'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGraph } from '@react-three/fiber'

import Material from '../../Material'
import { MainTowerBox } from './MainTowerBox'

export const MainTower = forwardRef(
  ({ nodes, envMap, backgroundHidden, onLoad, ...props }, ref) => {
    const gltfLoader = useRef(new GLTFLoader())
    const [scene, setScene] = useState(null)

    useEffect(() => {
      console.log('Torre 3')
      const urlGltf = 'models/neinor_sky/model/torre3.glb'
      gltfLoader.current.loadAsync(urlGltf).then((g) => {
        setScene(g.scenes[0])
      })
    }, [])

    const { nodes: originalNodes } = useGraph(scene)

    useEffect(() => {
      if (scene) {
        onLoad?.()
      }
    }, [scene, onLoad])

    return scene && nodes ? (
      <>
        <MainTowerBox mainTowerNode={scene} />
        <group {...props} dispose={null}>
          <group
            position={[114.04, 5.33, -31.76]}
            rotation={[0, 0, -Math.PI]}
            scale={[30.48, -30.48, 30.48]}
          >
            <mesh
              castShadow
              receiveShadow
              geometry={originalNodes.Muro_básico_Sate_7750890.geometry}
              // material={originalNodes.Muro_básico_Sate_7750890.material}
            >
              <Material
                original={originalNodes.Pared_gris.material}
                modified={nodes.Pared_gris.material}
                backgroundHidden={backgroundHidden}
                envMap={envMap}
              />
            </mesh>
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={originalNodes.Metal_gris_.geometry}
            position={[114.23, 5.43, -19.86]}
          >
            <Material
              original={originalNodes.Metal_gris_.material}
              modified={nodes.Metal_gris_.material}
              backgroundHidden={backgroundHidden}
              envMap={envMap}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={originalNodes.Pared_gris.geometry}
            position={[108.12, 4.91, -17.17]}
          >
            <Material
              original={originalNodes.Pared_gris.material}
              modified={nodes.Pared_gris.material}
              backgroundHidden={backgroundHidden}
              envMap={envMap}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={originalNodes.Soleria.geometry}
            position={[114.27, 43.61, -20.62]}
          >
            <Material
              original={originalNodes.Soleria.material}
              modified={nodes.Soleria.material}
              backgroundHidden={backgroundHidden}
              envMap={envMap}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={originalNodes.Pared_blanca.geometry}
            position={[108.12, 4.91, -17.17]}
          >
            <Material
              original={originalNodes.Pared_blanca.material}
              modified={nodes.Pared_blanca.material}
              backgroundHidden={backgroundHidden}
              envMap={envMap}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={originalNodes.Cristal.geometry}
            position={[124.72, 5.61, -38.23]}
            rotation={[Math.PI, Math.PI / 2, 0]}
            scale={[30.48, -30.48, 30.48]}
          >
            <Material
              original={originalNodes.Cristal.material}
              modified={nodes.Cristal.material}
              backgroundHidden={false}
              envMap={envMap}
            />
          </mesh>
          <mesh
            castShadow
            receiveShadow
            geometry={originalNodes.Barandillas.geometry}
            position={[124.72, 5.61, -38.23]}
            rotation={[Math.PI, 1.57, 0]}
            scale={[30.48, -30.48, 30.48]}
          >
            <Material
              original={originalNodes.Barandillas.material}
              modified={nodes.Barandillas.material}
              backgroundHidden={false}
              envMap={envMap}
            />
          </mesh>
        </group>
      </>
    ) : null
  }
)
