import { SimpleModel } from './SimpleModel'

export const VegetacionMuebles = ({
  nodes,
  envMap,
  backgroundHidden,
  onLoad
}) => {
  return (
    <SimpleModel
      name="VegetacionMuebles"
      src={'models/neinor_soto/model/VegMuebles.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible={!backgroundHidden}
    />
  )
}
export const Arboles = ({ nodes, envMap, backgroundHidden, onLoad }) => {
  return (
    <SimpleModel
      name="Arboles"
      src={'models/neinor_soto/model/arboles.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible={!backgroundHidden}
    />
  )
}
export const NombreCalles = ({ nodes, envMap, backgroundHidden, onLoad }) => {
  return (
    <SimpleModel
      name="NombreCalles"
      src={'models/neinor_soto/model/nombres calles.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible={!backgroundHidden}
    />
  )
}
export const ArbolesParque = ({ nodes, envMap, backgroundHidden, onLoad }) => {
  return (
    <SimpleModel
      name="ArbolesParque"
      src={'models/neinor_soto/model/arboles_parque.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible={!backgroundHidden}
    />
  )
}
export const Farolas = ({ nodes, envMap, backgroundHidden, onLoad }) => {
  return (
    <SimpleModel
      name="Farolas"
      src={'models/neinor_soto/model/farolas.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible={!backgroundHidden}
    />
  )
}
export const Coches = ({ nodes, envMap, backgroundHidden, onLoad }) => {
  return (
    <SimpleModel
      name="Coches"
      src={'models/neinor_soto/model/coches.glb'}
      nodes={nodes}
      envMap={envMap}
      onLoad={onLoad}
      visible={!backgroundHidden}
    />
  )
}
