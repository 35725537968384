import { useCallback } from 'react'
import { BoundingBox } from '../../BoundingBox'

export const MainTowerBox = ({ mainTowerNode }) => {
  const handleFakePointerEnter = useCallback((e) => {}, [])
  return (
    <BoundingBox
      node={mainTowerNode}
      renderOrder={100}
      userData={{ boundingBox: true }}
      materialsProps={{ color: '#0000FF', transparent: true, opacity: 0 }}
      onPointerEnter={handleFakePointerEnter}
    />
  )
}
