import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'
import { useCubeTexture } from '@react-three/drei'

import Camera from './Camera'
import { ModelGLTF } from './ModelGLTF'
// import ModelFBX from './ModelFBX'
// import Background from './Background'
import useH3DStore from '../../modules/useH3DStore'
import { getCurrentModel } from '../../modules/modelStore'

import CameraControls from './CameraControls'
import ModelFBX from './ModelFBX'

import HemisphereLight from './Lights/HemisphereLight'
import AmbientLight from './Lights/AmbientLight'
import DirectionalLight from './LightAnimation/DirectionalLight'
import { MAIN_MODE, mainModeSelector } from '../../modules/mainStore'
import { useEffect } from 'react'
import { loadCurrentPromotionDataAction } from '../../modules/promotionStore'
// import PointLight from './Lights/PointLight'
// import SpotLight from './Lights/SpotLight'

export const ThreeView = ({ visible }) => {
  const model = useH3DStore(getCurrentModel)
  const mainMode = useH3DStore(mainModeSelector)
  const loadCurrentPromotionData = useH3DStore(loadCurrentPromotionDataAction)
  const envMap = useCubeTexture(model.backgroundSrcs, { path: '/' })

  // const ext = useFBX('Exterior1_viviendas.fbx')
  // // const { nodes: nodes_ext, materials: materials_ext } = useGraph(ext)

  useEffect(() => {
    if (mainMode === MAIN_MODE.VIEW_3D_LOADING_DATA) {
      loadCurrentPromotionData()
    }
  }, [mainMode, loadCurrentPromotionData])

  return (
    <>
      <Canvas
        style={{ visibility: visible ? null : 'hidden' }}
        frameloop={visible ? 'demand' : 'never'}
        gl={{
          preserveDrawingBuffer: true,
          antialias: true,
          encoding: THREE.sRGBEncoding
        }}
        flat
        linear={false}
        performance={{ min: 0.8, max: 1 }}
        shadows
      >
        <CameraControls />
        <Camera />
        <HemisphereLight />
        <AmbientLight />
        <DirectionalLight />

        {/* <SpotLight /> */}
        {/* <PointLight /> */}
        {/* {lightRef.current && (
          <directionalLightHelper light={lightRef.current} />
        )} */}
        {/* {envMap && <Background envMap={envMap} />} */}
        {envMap && (model.type === 'glb' || model.type === 'gltf') && (
          <ModelGLTF id={model.id} src={model.src} envMap={envMap} />
        )}
        {envMap && model.type === 'fbx' && (
          <ModelFBX
            id={model.id}
            src={model.src}
            envMap={envMap}
            visible={visible}
          />
        )}
        {/* {model.type === 'fbx' && (
        <ModelFBX id={model.id} src={model.src} envMap={envMap} />
      )} */}
      </Canvas>
    </>
  )
}
export default ThreeView
