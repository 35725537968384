import { styled } from '@mui/material/styles'
import { mainModeSelector, MAIN_MODE } from '../../modules/mainStore'
import { getCurrentModel } from '../../modules/modelStore'
import useH3DStore from '../../modules/useH3DStore'
import Filter from '../Filter/Filter'
import Light from '../LightAnimation/Light'
import HouseDetails from '../HouseDetails/HouseDetails'
import InfoPanel from '../InfoPanel/InfoPanel'
import PlanView from '../PlanView/PlanView'
import ThreeView from '../ThreeView/ThreeView'

import Toolbar from '../Toolbar/Toolbar'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'mode'
})(({ theme, mode }) => ({
  width: '100%',
  height: '100%',
  minHeight: 0,
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  paddingTop:
    mode === MAIN_MODE.LOADING_MODEL_2 ||
    mode === MAIN_MODE.VIEW_3D ||
    mode === MAIN_MODE.VIEW_3D_LOADING_DATA ||
    mode === MAIN_MODE.PLAN
      ? 0
      : theme.topBar.height
}))

export const Center = () => {
  const model = useH3DStore(getCurrentModel)
  const mode = useH3DStore(mainModeSelector)

  return (
    <Root mode={mode}>
      {model && <ThreeView visible={mode !== MAIN_MODE.PLAN} />}
      {(mode === MAIN_MODE.LOADING_MODEL_2 ||
        mode === MAIN_MODE.VIEW_3D ||
        mode === MAIN_MODE.VIEW_3D_LOADING_DATA) && (
        <>
          <Toolbar />
          <Filter />
          <Light />
          <InfoPanel />
          <HouseDetails />
        </>
      )}
      {mode === MAIN_MODE.PLAN && <PlanView />}
    </Root>
  )
}
export default Center
